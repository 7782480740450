// src/@chakra-ui/gatsby-plugin/theme.js
import { extendTheme, textDecoration } from "@chakra-ui/react";
const theme = {
  colors: {
    primary: "rebeccapurple",
    brand: {
      light: "RGBA(255, 255, 255, 0.92)", // == "whiteAlpha.900",
      dark: "black",
      green: "#3d5b58",
      red: "#8C3A19",
      lightBlue: "#00829B",
      midBlue: "#003B4A",
      darkBlue: "#081F2D",
    },
    lightBlue: {
      700: "#00829B",
      800: "#00667A",
      900: "#004452",
    },
  },
  fonts: {
    heading: "Poppins, sans-serif",
    body: "LoraVariable",
    secondary: "Poppins, sans-serif",
  },
  fontWeights: {
    normal: 400,
    bold: 700,
  },
  // fontSizes: {
  //   "4xl": "2rem", // would force headers to 32px default
  // },
  layerStyles: {
    highlight: {
      bg: "brand.lightBlue",
      color: "white",
      a: {
        color: "inherit",
        _visited: {
          color: "brand.dark",
        },
      },
    },
  },
  textStyles: {
    mdx: {
      p: {
        marginTop: "0.6em",
        marginBottom: "0.6em",
      },
      h2: {
        fontSize: "3xl",
        marginTop: "1.5em",
        marginBottom: "0.6em",
      },
      h3: {
        fontSize: "2xl",
        marginTop: "1.5em",
        marginBottom: "0.6em",
      },
      h4: {
        fontSize: "xl",
        marginTop: "1.5em",
        marginBottom: "0.6em",
      },
      h5: {
        fontSize: "lg",
        marginTop: "1.5em",
        marginBottom: "0.6em",
      },
      h6: {
        fontSize: "md",
        marginTop: "1.5em",
        marginBottom: "0.6em",
      },
      ul: {
        marginTop: "0.4em",
        marginBottom: "0.6em",
      },
      li: {
        marginTop: "0.4em",
        marginBottom: "0.4em",
      },
    },
    simple: {
      // headers and body same size with some padding
      p: {
        marginTop: "0.6em",
        marginBottom: "0.6em",
      },
      h5: {
        fontFamily: "heading",
        fontWeight: "bold",
        fontSize: "lg",
        marginTop: "1.5em",
        marginBottom: "0.6em",
      },
    },
    boldList: {
      fontFamily: "heading",
      fontWeight: "bold",
    },
    form: {
      label: {
        // paddingTop: "1rem",
        fontFamily: "heading",
        fontWeight: "bold",
      },
      input: {
        borderColor: "black",
      },
      textArea: {
        borderColor: "black",
      },
    },
    plainLinks: {
      a: {
        color: "inherit",
        textDecoration: "none",
        _visited: {
          color: "inherit",
        },
      },
    },
    search: {
      ol: {
        listStyleType: "none",
      },
      a: {
        color: "inherit",
        textDecoration: "none",
        _visited: {
          color: "inherit",
          h2: {
            color: "brand.red",
          },
        },
        _hover: {
          textDecoration: "none",
          h2: {
            textDecoration: "underline",
          },
        },
        h2: {
          color: "brand.lightBlue",
        },
      },
    },
  },
  components: {
    Link: {
      variants: {
        // you can name it whatever you want
        primary: {
          color: "brand.lightBlue",
          textDecoration: "underline",
          _visited: {
            color: "brand.red",
          },
        },
      },
      defaultProps: {
        // you can name it whatever you want
        variant: "primary",
      },
    },
  },
  styles: {
    global: {
      // styles for the `body`
      body: {
        bg: "whiteAlpha.900", //"#081f2d", // use mode("gray.800", "whiteAlpha.900")(props) when have color modes
        color: "black",
        fontWeight: "normal",
        fontSize: "lg",
      },
      // we may want to override this border color default
      "*, *::before, &::after": {
        borderStyle: "none",
        // borderColor: mode("gray.200", "whiteAlpha.300")(props),
      },
      figcaption: { textAlign: "center", fontStyle: "italic" },
      // html: {
      //   fontSize: ["100%", null, "112.5%"],
      // },
      // },
      // textStyles: {
      //   spaced: {
      //     marginBlockStart: "1rem",
      //     marginBlockEnd: "1rem",
      //   },
      // // styles for the `a`
      // a: {
      //   color: "teal.500",
      //   _hover: {
      //     textDecoration: "underline",
      //   },
      // },
    },
  },
};

export default extendTheme(theme);

// Notes on colour:
// - schemes standardly run from 50 -> 900, which in HSL is 95 -> 10 [but often adjusted to end around 20]
// - our core colours start at 30.  Do we set up a range centered there, or centered at 50?
// - only implication right now is for buttons.
// - button uses 50/40/30 (500/600/700)
// - we could try 30/22/16
